import {
  ButtonHTMLAttributes,
  InputHTMLAttributes,
  ReactNode,
  TextareaHTMLAttributes,
} from 'react';
import { FieldError } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { DatePickerProps, MenuProps, SelectProps } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FilterValue } from 'antd/es/table/interface';
import { DefaultOptionType } from 'antd/es/select';

export enum DateFormats {
  DMY = 'DD.MM.YYYY',
  DMYHms = 'DD.MM.YYYY HH:mm:ss',
  HMS = 'HH:mm:ss',
  dashYMD = 'YYYY-MM-DD',
  request = 'YYYY-MM-DD HH:mm:ss',
}

export type ButtonView = 'primary' | 'secondary' | 'link' | 'transparent';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  view?: ButtonView;
  withoutIndentation?: boolean;
}

export interface DropdownSaveProps {
  items?: MenuProps['items'];
  data?: Record<string, FilterValue | null>;
  buttonProps?: ButtonProps;
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  errorClassName?: string;
  image?: string;
  error?: FieldError;
  type?: string;
}

export type SelectView = 'primary' | 'secondary' | 'white';

export interface ISelectProps extends SelectProps {
  view?: SelectView;
  error?: FieldError;
  errorClassName?: string;
  personsSearch?: DefaultOptionType[] | null;
  customSelect?: boolean;
  hasAllOption?: boolean;
}

export type PickerView = 'primary' | 'white';
export type IRangePickerProps = RangePickerProps & {
  view?: PickerView;
  popupClassName?: string;
};

export type IDatePickerProps = DatePickerProps & {
  view?: PickerView;
  popupClassName?: string;
};

export type TTypographyType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'p'
  | 'error'
  | 'title'
  | 'primarySelect'
  | 'secondarySelect';

type TagComponent = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span' | 'p' | 'strong';

export interface ITypographyProps {
  component?: TagComponent;
  type?: TTypographyType;
}

export enum Section {
  CONTROL_SIZ = 'siz',
  CONTROL_ACCESS = 'access',
}

export enum DecisionSector {
  CONTROL_ACCESS = 'access-control',
}

export interface IConfirmPersonReq {
  warning_id?: number;
  person_id: number | null;
}

export interface IConfirmDecisionRes {
  status: boolean;
}

export interface IConfirmDecisionReq {
  warningId?: number;
  decision: boolean;
  comment: string | null;
  timeDelay: number;
}

export enum TabsTypes {
  History = 'history',
  Online = 'online',
  Badges = 'badges',
  Cameras = 'cameras',
  Steams = 'steams',
  Persons = 'persons',
  Activity = 'activity',
  Video = 'video',
  Steam = 'steam',
  Doors = 'doors',
  Users = 'users',
  Settings = 'activity',
  SmsMailing = 'smsMailing',
}

export interface ModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit?: (formData: any) => void;
  showTime?: boolean;
  children?: ReactNode;
  id?: number | undefined;
  destroyOnClose?: boolean;
  preview?: string;
  className?: string;
  videos?: number;
}

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  wrapperClassName?: string;
  className?: string;
  errors?: FieldError;
}

export enum SocketModules {
  DASHBOARD = 'dashboard',
  CONTROL_ACTIVITY = 'activity',
  CONTROL_PPE = 'video',
  CONTROL_STEAM = 'steam',
  CONTROL_DOORS = 'doors',
  HARDWARE = 'hardware',
  SETTINGS = 'settings',
  CONTROL_ACCESS = 'accessControl',
  SMS_MAILING = 'sms-sending',
}

export interface CardItemHistoryProps {
  value?: string | number;
  label: string;
  src?: string;
  comment?: string;
  showModal?: () => void;
  showModals?: (type: 'video' | 'comment' | 'photo') => void;
}

export interface SliderComponentProps extends FieldValues {
  min?: number;
  max?: number;
  sliderName: string;
  disabled?: boolean;
  defaultValue?: number;
  className?: string;
  inputClassName?: string;
}

export interface SocketConnectionProps {
  module: SocketModules;
  page?: number;
  tab?: TabsTypes;
}

export interface LoginData {
  token: string;
  tokenType: string;
  userName: string;
  platformsStorage: { id: number; name: string; description: null }[];
}

export interface IPlatformConfig {
  activityControl: boolean;
  doorsControl: boolean;
  hardware: {
    badgesAccess: boolean;
    camerasAccess: boolean;
    doorsAccess: boolean;
    personsAccess: boolean;
    steamAccess: boolean;
  };
  reports: {
    activityAccess: boolean;
    doorsAccess: boolean;
    personsAccess: boolean;
    steamAccess: boolean;
    videoAccess: boolean;
  };
  settings: {
    activityAccess: boolean;
    camerasAccess: boolean;
    doorsAccess: boolean;
    steamAccess: boolean;
    usersAccess: boolean;
    smsMailingAccess: boolean;
  };
  steamControl: boolean;
  videoControl: boolean;
}

export interface CheckboxProps {
  value: boolean;
  onChange?: (event: CheckboxChangeEvent) => void;
}

export interface ILastOpenTabs {
  activityControl?: TabsTypes;
  videoControl?: TabsTypes;
  doorsControl?: TabsTypes;
  steamControl?: TabsTypes;
  reports?: TabsTypes;
  hardware?: TabsTypes;
  settings?: TabsTypes;
}

export interface IUserConfig {
  lastOpenTabs?: ILastOpenTabs;
}

export type FileFormat = 'pdf' | 'xlsx';

export interface ICheckArray {
  text: string;
  value: string;
  isChecked: any;
}
